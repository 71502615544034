// extracted by mini-css-extract-plugin
export var about = "fundraiserTemplate-module--about--5fda2";
export var address = "fundraiserTemplate-module--address--9c853";
export var blackBorder = "fundraiserTemplate-module--blackBorder--6ca3c";
export var buttonContainer = "fundraiserTemplate-module--button-container--15869";
export var desktopDirectionButton = "fundraiserTemplate-module--desktopDirectionButton--ca2e6";
export var details = "fundraiserTemplate-module--details--f4d3f";
export var detailsContainer = "fundraiserTemplate-module--detailsContainer--ffb6f";
export var detailsHeading = "fundraiserTemplate-module--detailsHeading--03a1f";
export var detailsTitle = "fundraiserTemplate-module--detailsTitle--16c14";
export var eventDetails = "fundraiserTemplate-module--eventDetails--7cc48";
export var eventDetailsAddress = "fundraiserTemplate-module--eventDetailsAddress--b2742";
export var eventDetailsAddressContainer = "fundraiserTemplate-module--eventDetailsAddressContainer--174ed";
export var eventDetailsContainer = "fundraiserTemplate-module--eventDetailsContainer--84c27";
export var eventDetailsEyebrow = "fundraiserTemplate-module--eventDetailsEyebrow--dc9bc";
export var eventDetailsOuter = "fundraiserTemplate-module--eventDetailsOuter--6f386";
export var eventDetailsParagraph = "fundraiserTemplate-module--eventDetailsParagraph--70530";
export var eventTime = "fundraiserTemplate-module--event-time--3099a";
export var fundraiserDetails = "fundraiserTemplate-module--fundraiser-details--9884d";
export var fundraiserPage = "fundraiserTemplate-module--fundraiser-page--4658a";
export var globeIcon = "fundraiserTemplate-module--globeIcon--ed2c1";
export var map = "fundraiserTemplate-module--map--063b5";
export var mobileDirectionButton = "fundraiserTemplate-module--mobileDirectionButton--cd991";
export var nickname = "fundraiserTemplate-module--nickname--818b4";
export var oneLoveGroup = "fundraiserTemplate-module--oneLoveGroup--543ad";
export var phoneNumber = "fundraiserTemplate-module--phoneNumber--ffa52";
export var redOverlay = "fundraiserTemplate-module--redOverlay--ba0a4";
export var subTitle = "fundraiserTemplate-module--sub-title--91469";
export var textParagraph = "fundraiserTemplate-module--textParagraph--30c36";
export var title = "fundraiserTemplate-module--title--e15f1";