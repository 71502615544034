import React, { useEffect } from 'react'

import * as Styles from './toaster.module.scss'

type Props = {
  message: string
  onClose: Function
}

const Toaster = ({ message, onClose }: Props) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [onClose])

  return <p className={Styles.toaster}>{message}</p>
}

export default Toaster
